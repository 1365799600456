import {Component, OnDestroy} from '@angular/core';
import {NativeService} from '../../_service/native.service';
import {NGXLogger} from "ngx-logger";
import {NoticeService} from "../../domain/notice/notice.service";
import {MatDialog} from "@angular/material/dialog";
import {InfoDialogComponent} from "../../shared/dialog/info-dialog/info-dialog.component";
import {MediaChange, MediaObserver} from "@ngbracket/ngx-layout";
import {Subscription} from "rxjs";

declare let window: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnDestroy {
  showStoreLink = false;
  partnerLogos = [
    'assets/img/partner/logo-round-8percent.png',
    'assets/img/partner/logo-round-cocktailfunding.png',
    'assets/img/partner/logo-round-zoomfund.png',
    'assets/img/partner/logo-round-oasisfund.png',
    'assets/img/partner/logo-round-yfund.png',
    'assets/img/partner/logo-round-leadingplus.png',
    'assets/img/partner/logo-round-acefunding.png',
    'assets/img/partner/logo-round-fmfunding.png',
    'assets/img/partner/logo-round-dailyfunding.png',
    'assets/img/partner/logo-round-miracle.png',
    'assets/img/partner/logo-round-namo.png',
    'assets/img/partner/logo-round-daonfunding.png',
    'assets/img/partner/logo-round-smartfunding.png',
    'assets/img/partner/logo-round-titaninvest.png'
  ]
  notice: any;
  isMobile = false;
  isIrosNotice = false;
  private readonly mediaWatcher: Subscription;

  constructor(private log: NGXLogger,
              private nativeService: NativeService,
              private noticeService: NoticeService,
              private matDialog: MatDialog,
              private media: MediaObserver) {
    this.showStoreLink = !this.nativeService.isNative();

    this.mediaWatcher = this.media.asObservable().subscribe((change: MediaChange[]) => {
      this.isMobile = change[0].mqAlias === 'xs';
    });

    this.noticeService.getMainPageNotice()
      .subscribe(response => {
        if (response) {
          this.notice = response;
          this.isIrosNotice = this.notice.title === "인터넷등기소 점검으로 인한 서비스 제한 안내";
        }
      })
  }

  ngOnDestroy(): void {
    if (this.mediaWatcher) {
      this.mediaWatcher.unsubscribe();
    }
  }

  openNotice() {
    if (this.notice) {
      this.matDialog.open(InfoDialogComponent, {
        data: {
          title: this.notice.title,
          content: this.notice.webContent.replaceAll("<br>", "\n").replaceAll(/(<([^>]+)>)/ig, '')
        }
      });
    }
  }

  openPlayStore() {
    window.open('https://play.google.com/store/apps/details?id=kr.co.lawalliance.checkteam')
  }

  openAppStore() {
    if (this.nativeService.isiOS) {
      window.open('itms-apps://itunes.apple.com/kr/app/apple-store/id1578403569?mt=8');
    } else {
      window.open('https://itunes.apple.com/kr/app/apple-store/id1578403569?mt=8');
    }
  }
}
