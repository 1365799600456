import * as i0 from '@angular/core';
import { EventEmitter, PLATFORM_ID, Component, ChangeDetectionStrategy, Inject, Input, ViewChild, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { isPlatformServer, CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
const _c0 = ["wrapper"];
const _c1 = ["contentContainer"];
const _c2 = ["loadingContainer"];
const _c3 = ["circle"];
const _c4 = ["*"];
class NgxPullToRefreshComponent {
  chagneDetectorRef;
  platformId;
  static touchstartEventList = [];
  static touchmoveEventList = [];
  static scrollEventList = [];
  static touchendEventList = [];
  isServer;
  distance = 0;
  startScreenY = 0;
  previousX = 0;
  previousY = 0;
  spinnerColor = '#F7C223';
  isHorizontal = false;
  customClass = "";
  _targetElement;
  set targetElement(value) {
    if (!this.isServer) {
      this.removeEventListener();
      this._targetElement = value;
      this.ele = this._targetElement ?? this.wrapperElement.nativeElement;
      if (this._isEnable) {
        this.addEventListener();
      } else {
        this.removeEventListener();
      }
    }
  }
  _isEnable = true;
  set isEnable(value) {
    if (!this.isServer) {
      this._isEnable = value;
      if (this._isEnable) {
        this.addEventListener();
      } else {
        this.removeEventListener();
      }
    }
  }
  isRefresh = false;
  isScrollTop = false;
  isOnScrollBottom = false;
  wrapperElement;
  contentContainer;
  loadingbar;
  circleSvgElement;
  CIRCLE_OFFSET = 187;
  distanceForRefresh = 40;
  spinnerSize = 300;
  scrollPullPercent = 20;
  isPlayingAnimation = false;
  refresh = new EventEmitter();
  refreshCompleteSubject = new Subject();
  loadMore = new EventEmitter();
  ele;
  isContainWrapper = false;
  touchstartEvent = evt => {
    this.onTouchStart(evt);
  };
  touchmoveEvent = evt => {
    this.onTouchMove(evt);
  };
  scrollEvent = evt => {
    this.onScroll(evt);
  };
  touchendEvent = evt => {
    this.onTouchEnd(evt);
  };
  constructor(chagneDetectorRef, platformId) {
    this.chagneDetectorRef = chagneDetectorRef;
    this.platformId = platformId;
    this.isServer = isPlatformServer(this.platformId);
  }
  ngOnInit() {
    if (!this.isServer) {
      this.refreshCompleteSubject.subscribe(() => {
        this.isPlayingAnimation = false;
        this.restoreWrapper();
        this.restoreLoadingbar();
      });
      this.ele = this._targetElement ?? this.contentContainer.nativeElement;
      if (this._isEnable) {
        this.addEventListener();
      } else {
        this.removeEventListener();
      }
      this.distanceForRefresh = +this.distanceForRefresh;
    }
  }
  ngOnDestroy() {
    this.removeEventListener();
    this.refreshCompleteSubject?.complete();
  }
  onTouchMove($event) {
    if (this.isPlayingAnimation) {
      return;
    } else if (!this._isEnable) {
      return;
    } else if (!this.isContainWrapper) {
      return;
    } else if (this.isHorizontal) {
      return;
    }
    const currentScreenY = $event.touches[0].screenY;
    if (this.ele.scrollTop <= 0 && this.distance > 0) {
      this.isScrollTop = true;
    } else {
      this.isScrollTop = false;
    }
    const loadingbar = this.loadingbar.nativeElement;
    if (this.startScreenY > currentScreenY) {
      this.distance = 0;
    } else {
      this.distance = Math.abs(currentScreenY - this.startScreenY);
    }
    if (this.distance > this.distanceForRefresh) {
      this.distance = this.distanceForRefresh;
    }
    this.isRefresh = this.isScrollTop && this.distance >= this.distanceForRefresh;
    const contentContainerElement = this.contentContainer.nativeElement;
    if (this.isScrollTop && this.distance >= 0) {
      contentContainerElement.style.transform = `translateY(${this.distance}px)`;
      loadingbar.style.visibility = 'visible';
    } else {
      this.restoreWrapper();
      loadingbar.style.visibility = 'hidden';
    }
    this.scrollPullPercent = this.distance / this.distanceForRefresh * 100;
    this.isRefresh = this.scrollPullPercent >= 100 && this.isScrollTop;
    if (this.scrollPullPercent < 0) {
      this.scrollPullPercent = 0;
    }
    this.drawCircle(this.scrollPullPercent);
  }
  onScroll($event) {
    if (!this._isEnable) {
      return;
    }
    const scrollX = this.ele.scrollLeft;
    const scrollY = this.ele.scrollTop;
    if (this.isHorizontal) {
      this.isOnScrollBottom = scrollX >= 0 && scrollX > this.previousX && this.ele.clientWidth + this.ele.scrollLeft >= this.ele.scrollWidth * 0.85;
    } else {
      this.isOnScrollBottom = scrollY >= 0 && scrollY > this.previousY && this.ele.clientHeight + this.ele.scrollTop >= this.ele.scrollHeight * 0.85;
    }
    this.previousX = scrollX;
    this.previousY = scrollY;
    if (this.isOnScrollBottom && this.loadMoreFunction && document.contains(this.wrapperElement.nativeElement)) {
      this.loadMoreFunction();
    }
  }
  onTouchStart($event) {
    this.chagneDetectorRef.detectChanges();
    if (this.isPlayingAnimation) {
      return;
    } else if (this.isHorizontal) {
      return;
    }
    const path = this.getParentElementList($event.target); // FIXME 'as'
    this.isContainWrapper = false;
    for (const item of path) {
      if (item === this.wrapperElement.nativeElement) {
        this.isContainWrapper = true;
        break;
      }
    }
    if (!this.isContainWrapper) {
      return;
    } else if (!this._isEnable) {
      return;
    }
    this.restoreWrapper();
    this.restoreLoadingbar();
    this.isRefresh = false;
    this.startScreenY = $event.touches[0].screenY;
    this.distance = 0;
  }
  onTouchEnd($event) {
    if (this.isPlayingAnimation) {
      return;
    } else if (!this.isContainWrapper) {
      return;
    } else if (this.isHorizontal) {
      return;
    }
    if (this.isRefresh && document.contains(this.wrapperElement.nativeElement)) {
      this.refreshFunction();
    } else {
      this.restoreWrapper();
      this.restoreLoadingbar();
    }
  }
  restoreWrapper() {
    this.contentContainer.nativeElement.style.position = 'relative';
    this.contentContainer.nativeElement.style.transform = `translateY(0)`;
    // this.loadingbar.nativeElement.style.transform = `translateY(0px)`;
    this.hiddenLoadingbar();
  }
  hiddenLoadingbar() {
    this.loadingbar.nativeElement.style.visibility = 'hidden';
  }
  restoreLoadingbar() {
    this.scrollPullPercent = 0;
    this.drawCircle(0);
    this.loadingbar.nativeElement.style.transform = `translateY(0)`;
    this.hiddenLoadingbar();
  }
  refreshFunction() {
    this.isPlayingAnimation = true;
    this.refresh.emit(this.refreshCompleteSubject);
  }
  loadMoreFunction() {
    this.loadMore.emit(true);
  }
  drawCircle(percentage) {
    const offset = this.CIRCLE_OFFSET - this.CIRCLE_OFFSET * (Math.abs(percentage) / 100);
    this.circleSvgElement.nativeElement.style.strokeDashoffset = `${offset}px`;
  }
  getParentElementList(srcElement) {
    const parents = [];
    let elem = srcElement;
    while (elem?.parentElement && elem?.parentNode?.nodeName?.toLowerCase() !== 'body') {
      elem = elem.parentElement;
      parents.push(elem);
    }
    return parents;
  }
  addEventListener() {
    this.ele?.addEventListener('touchstart', this.touchstartEvent, false);
    this.ele?.addEventListener('touchmove', this.touchmoveEvent, false);
    this.ele?.addEventListener('touchend', this.touchendEvent, false);
    let scrollTarget;
    if (this.ele?.tagName === 'HTML') {
      scrollTarget = window;
    } else {
      scrollTarget = this.ele;
    }
    scrollTarget?.addEventListener('scroll', this.scrollEvent, false);
    NgxPullToRefreshComponent.touchstartEventList.push(this.touchstartEvent);
    NgxPullToRefreshComponent.touchmoveEventList.push(this.touchmoveEvent);
    NgxPullToRefreshComponent.scrollEventList.push(this.scrollEvent);
    NgxPullToRefreshComponent.touchendEventList.push(this.touchendEvent);
  }
  removeEventListener() {
    this.ele?.removeEventListener('touchstart', this.touchstartEvent);
    this.ele?.removeEventListener('touchmove', this.touchmoveEvent);
    this.ele?.removeEventListener('scroll', this.scrollEvent);
    this.ele?.removeEventListener('touchend', this.touchendEvent);
  }
  clearAllEvent() {
    NgxPullToRefreshComponent.touchstartEventList.forEach(evt => {
      this.ele?.removeEventListener('touchstart', evt);
    });
    NgxPullToRefreshComponent.touchmoveEventList.forEach(evt => {
      this.ele?.removeEventListener('touchmove', evt);
    });
    NgxPullToRefreshComponent.scrollEventList.forEach(evt => {
      this.ele?.removeEventListener('scroll', evt);
    });
    NgxPullToRefreshComponent.touchendEventList.forEach(evt => {
      this.ele?.removeEventListener('touchend', evt);
    });
  }
  static ɵfac = function NgxPullToRefreshComponent_Factory(t) {
    return new (t || NgxPullToRefreshComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(PLATFORM_ID));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: NgxPullToRefreshComponent,
    selectors: [["ngx-pull-to-refresh"]],
    viewQuery: function NgxPullToRefreshComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 7);
        i0.ɵɵviewQuery(_c1, 7);
        i0.ɵɵviewQuery(_c2, 5);
        i0.ɵɵviewQuery(_c3, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.wrapperElement = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.contentContainer = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.loadingbar = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.circleSvgElement = _t.first);
      }
    },
    inputs: {
      spinnerColor: "spinnerColor",
      isHorizontal: "isHorizontal",
      customClass: "customClass",
      targetElement: "targetElement",
      isEnable: "isEnable",
      distanceForRefresh: "distanceForRefresh",
      spinnerSize: "spinnerSize"
    },
    outputs: {
      refresh: "refresh",
      loadMore: "loadMore"
    },
    ngContentSelectors: _c4,
    decls: 11,
    vars: 9,
    consts: [["wrapper", ""], ["loadingContainer", ""], ["spinner", ""], ["circle", ""], ["contentContainer", ""], [1, "ngx-ptr-str-container"], [1, "ngx-ptr-loadingbar-container"], ["viewBox", "0 0 66 66", "xmlns", "http://www.w3.org/2000/svg", 1, "ngx-ptr-spinner"], ["fill", "none", "stroke-width", "6", "stroke-linecap", "round", "cx", "33", "cy", "33", "r", "30", 1, "ngx-ptr-path"], [1, "ngx-ptr-content-container", 3, "ngClass"]],
    template: function NgxPullToRefreshComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "div", 5, 0)(2, "div", 6, 1);
        i0.ɵɵnamespaceSVG();
        i0.ɵɵelementStart(4, "svg", 7, 2);
        i0.ɵɵelement(6, "circle", 8, 3);
        i0.ɵɵelementEnd()();
        i0.ɵɵnamespaceHTML();
        i0.ɵɵelementStart(8, "div", 9, 4);
        i0.ɵɵprojection(10);
        i0.ɵɵelementEnd()();
      }
      if (rf & 2) {
        i0.ɵɵclassProp("horizontal", ctx.isHorizontal);
        i0.ɵɵadvance(4);
        i0.ɵɵclassProp("ngx-ptr-animation", ctx.isPlayingAnimation);
        i0.ɵɵattribute("width", ctx.spinnerSize)("height", ctx.spinnerSize);
        i0.ɵɵadvance(2);
        i0.ɵɵstyleProp("stroke", ctx.spinnerColor);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngClass", ctx.customClass);
      }
    },
    dependencies: [i1.NgClass],
    styles: [".ngx-ptr-str-container[_ngcontent-%COMP%]{display:block;width:100%;height:100%;min-height:100%;position:relative;overscroll-behavior-x:auto;overscroll-behavior-y:contain}.ngx-ptr-str-container.horizontal[_ngcontent-%COMP%]{overflow-x:auto;overflow-y:hidden;overscroll-behavior-x:contain;overscroll-behavior-y:auto}.ngx-ptr-content-container[_ngcontent-%COMP%]{width:100%;display:block;position:relative;height:100%;overflow:auto;transition:transform .2s cubic-bezier(0,0,.2,1)}.ngx-ptr-loadingbar-container[_ngcontent-%COMP%]{margin-top:6px;transition:transform .2s ease-out;will-change:transform;transition:transform .2s cubic-bezier(0,0,.2,1);top:0;visibility:hidden;display:flex;z-index:9999;position:absolute;width:100%;text-align:center;justify-content:center}.ngx-ptr-spinner[_ngcontent-%COMP%]{pointer-events:none;will-change:transform;transform:rotate(-90deg)}.ngx-ptr-spinner[_ngcontent-%COMP%]   .ngx-ptr-path[_ngcontent-%COMP%]{transition:all .2s ease-out;stroke-dasharray:187;stroke:#f7c223;stroke-dashoffset:187}.ngx-ptr-animation.ngx-ptr-spinner[_ngcontent-%COMP%]{animation:_ngcontent-%COMP%_ngx-ptr-rotator 1.4s linear infinite}.ngx-ptr-animation[_ngcontent-%COMP%]   .ngx-ptr-path[_ngcontent-%COMP%]{stroke-dasharray:187;stroke-dashoffset:0;transform-origin:center;animation:_ngcontent-%COMP%_ngx-ptr-dash 1.4s ease-in-out infinite,ngx-ptr-colors 5.6s ease-in-out infinite}@keyframes _ngcontent-%COMP%_ngx-ptr-rotator{0%{transform:rotate(0)}to{transform:rotate(270deg)}}@keyframes _ngcontent-%COMP%_ngx-ptr-colors{0%{stroke:#4285f4}25%{stroke:#de3e35}50%{stroke:#f7c223}75%{stroke:#1b9a59}to{stroke:#4285f4}}@keyframes _ngcontent-%COMP%_ngx-ptr-dash{0%{stroke-dashoffset:187}50%{stroke-dashoffset:46.75;transform:rotate(135deg)}to{stroke-dashoffset:187;transform:rotate(450deg)}}"],
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPullToRefreshComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-pull-to-refresh',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div\n\t#wrapper\n\tclass=\"ngx-ptr-str-container\"\n\t[class.horizontal]=\"isHorizontal\">\n  <div #loadingContainer class=\"ngx-ptr-loadingbar-container\">\n    <svg #spinner [class.ngx-ptr-animation]=\"isPlayingAnimation\" class=\"ngx-ptr-spinner\" [attr.width]=\"spinnerSize\" [attr.height]=\"spinnerSize\" viewBox=\"0 0 66 66\" xmlns=\"http://www.w3.org/2000/svg\">\n      <circle #circle [style.stroke]=\"spinnerColor\" class=\"ngx-ptr-path\" fill=\"none\" stroke-width=\"6\" stroke-linecap=\"round\" cx=\"33\" cy=\"33\" r=\"30\"></circle>\n    </svg>\n  </div>\n  <div #contentContainer class=\"ngx-ptr-content-container\" [ngClass]=\"customClass\"> \n    <ng-content></ng-content>\n  </div>\n</div>\n",
      styles: [".ngx-ptr-str-container{display:block;width:100%;height:100%;min-height:100%;position:relative;overscroll-behavior-x:auto;overscroll-behavior-y:contain}.ngx-ptr-str-container.horizontal{overflow-x:auto;overflow-y:hidden;overscroll-behavior-x:contain;overscroll-behavior-y:auto}.ngx-ptr-content-container{width:100%;display:block;position:relative;height:100%;overflow:auto;transition:transform .2s cubic-bezier(0,0,.2,1)}.ngx-ptr-loadingbar-container{margin-top:6px;transition:transform .2s ease-out;will-change:transform;transition:transform .2s cubic-bezier(0,0,.2,1);top:0;visibility:hidden;display:flex;z-index:9999;position:absolute;width:100%;text-align:center;justify-content:center}.ngx-ptr-spinner{pointer-events:none;will-change:transform;transform:rotate(-90deg)}.ngx-ptr-spinner .ngx-ptr-path{transition:all .2s ease-out;stroke-dasharray:187;stroke:#f7c223;stroke-dashoffset:187}.ngx-ptr-animation.ngx-ptr-spinner{animation:ngx-ptr-rotator 1.4s linear infinite}.ngx-ptr-animation .ngx-ptr-path{stroke-dasharray:187;stroke-dashoffset:0;transform-origin:center;animation:ngx-ptr-dash 1.4s ease-in-out infinite,ngx-ptr-colors 5.6s ease-in-out infinite}@keyframes ngx-ptr-rotator{0%{transform:rotate(0)}to{transform:rotate(270deg)}}@keyframes ngx-ptr-colors{0%{stroke:#4285f4}25%{stroke:#de3e35}50%{stroke:#f7c223}75%{stroke:#1b9a59}to{stroke:#4285f4}}@keyframes ngx-ptr-dash{0%{stroke-dashoffset:187}50%{stroke-dashoffset:46.75;transform:rotate(135deg)}to{stroke-dashoffset:187;transform:rotate(450deg)}}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], {
    spinnerColor: [{
      type: Input
    }],
    isHorizontal: [{
      type: Input
    }],
    customClass: [{
      type: Input
    }],
    targetElement: [{
      type: Input
    }],
    isEnable: [{
      type: Input
    }],
    wrapperElement: [{
      type: ViewChild,
      args: ['wrapper', {
        static: true
      }]
    }],
    contentContainer: [{
      type: ViewChild,
      args: ['contentContainer', {
        static: true
      }]
    }],
    loadingbar: [{
      type: ViewChild,
      args: ['loadingContainer']
    }],
    circleSvgElement: [{
      type: ViewChild,
      args: ['circle']
    }],
    distanceForRefresh: [{
      type: Input
    }],
    spinnerSize: [{
      type: Input
    }],
    refresh: [{
      type: Output
    }],
    loadMore: [{
      type: Output
    }]
  });
})();
class NgxPullToRefreshModule {
  static ɵfac = function NgxPullToRefreshModule_Factory(t) {
    return new (t || NgxPullToRefreshModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgxPullToRefreshModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPullToRefreshModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxPullToRefreshComponent],
      imports: [CommonModule],
      exports: [NgxPullToRefreshComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-pull-to-refresh
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxPullToRefreshComponent, NgxPullToRefreshModule };
