import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainComponent} from './main.component';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import {defineElement} from "lord-icon-element";
import lottie from "lottie-web";
import {FooterModule} from "../../shared/footer/footer.module";
import {NoticeService} from "../../domain/notice/notice.service";
import {InfoDialogModule} from "../../shared/dialog/info-dialog/info-dialog.module";
import {MatDialogModule} from "@angular/material/dialog";
import {SwiperModule} from "../../shared/swiper/swiper.module";

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [MainComponent],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatTooltipModule,
    MatMenuModule,
    FooterModule,
    MatDialogModule,
    SwiperModule,
    InfoDialogModule
  ],
  providers: [NoticeService]
})
export class MainModule {
  constructor() {
    defineElement(lottie.loadAnimation);
  }
}
