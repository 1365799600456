import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {UserService} from './domain/user/service/user.service';
import {NGXLogger} from 'ngx-logger';
import {User} from './domain/user/model/user';
import {LoginDialogService} from './shared/dialog/login-dialog/login-dialog.service';
import {NavigationEnd, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MediaChange, MediaObserver} from '@ngbracket/ngx-layout';
import {Subject} from 'rxjs';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MobileFooterSheetComponent} from './shared/bottom-sheet/mobile-footer-sheet/mobile-footer-sheet.component';
import {SessionStorageService} from './_service/session-storage.service';
import {NotificationService} from './domain/notification/notification.service';
import {NativeService} from './_service/native.service';
import {Analysis} from "./analysis/_view/analysis";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  targetElement: Element | undefined;
  enableRefresh = true;
  isLogin: boolean | unknown;
  mobile = true;
  ios = false;
  countNotifications: number | undefined = undefined;
  activeMobileFooterIcon = '';

  constructor(private log: NGXLogger,
              private media: MediaObserver,
              private nativeService: NativeService,
              private router: Router,
              private matDialog: MatDialog,
              private matBottomSheet: MatBottomSheet,
              private userService: UserService,
              private notificationService: NotificationService,
              private loginDialogService: LoginDialogService,
              private sessionStorage: SessionStorageService) {
    this.media.asObservable().subscribe((change: MediaChange[]) => {
      this.mobile = change[0].mqAlias === 'xs';
      if (this.mobile) {
        this.enableRefresh = true;
      }
    });
    this.ios = this.nativeService.isNativeiOS();

    this.userService.subscribe()
      .subscribe((user: User | undefined) => {
        this.isLogin = user !== undefined;
      });

    this.notificationService.subscribe()
      .subscribe(count => {
        this.countNotifications = count;
      });

    this.userService.checkSession()
      .subscribe(result => {
        this.isLogin = result;
        this.checkCountNotifications();
      });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.log.debug('Changed Url: ', event.url)
        this.checkCountNotifications();
        this.checkMobileFooterIcon(event);
      }
    });
  }

  @HostListener('window:scroll', ['$event.target'])
  onWindowScroll() {
    this.enableRefresh = window.scrollY <= 0
  }

  ngOnInit() {
    this.targetElement = document.querySelector('app-root')!;
  }

  myRefreshEvent(event: Subject<any>) {
        const previousUrl = this.router.url;

        this.router.navigateByUrl('/refresh', {skipLocationChange: true})
          .then(() => {
            setTimeout(() => {
              this.router.navigateByUrl(previousUrl)
                .then(() => {
                  event.next('');
                });
            }, 300);
          });
  }

  ngAfterViewInit(): void {
    this.matDialog.afterOpened
      .subscribe(() => {
        this.log.debug('Dialog After Opened');
        if (this.mobile) {
          this.enableRefresh = false;
        }
      });

    this.matDialog.afterAllClosed
      .subscribe(() => {
        this.log.debug('Dialog After Closed');
        if (this.mobile) {
          this.enableRefresh = true;
        }
      });
  }

  login() {
    this.loginDialogService.openLoginDialog();
  }

  logout() {
    this.userService.logout();
    this.sessionStorage.clear();
    this.nativeService.forgetMe();
  }

  openBottomSheet(type: string): void {
    let links: any[];

    if (type === 'ANALYSIS') {
      links = [
        {icon: 'article', name: '신규 권리분석', path: '/analysis', queryParams: {}},
        {icon: 'monitor_heart', name: '권리분석 보고서', path: '/analysis/list', queryParams: {}},
        {icon: 'bookmark_add', name: '쿠폰 구입', path: '/coupon', queryParams: {}},
        {icon: 'fmd_bad', name: '서비스 안내', path: '/analysis/info', queryParams: {}}
      ]
    } else if (type === 'HOUSE') {
      links = [
        {icon: 'notifications', name: '등기변경알림 부동산', path: '/house/list', queryParams: {}},
        {icon: 'add_location', name: '서비스 신청', path: '/house/register', queryParams: {type: 'MY_HOUSE'}},
        {icon: 'file_present', name: '일괄 신청', path: '/house/batch', queryParams: {}},
        {icon: 'format_list_numbered_rtl', name: '일괄 연장', path: '/house/extends', queryParams: {}},
        {icon: 'bookmark_add', name: '쿠폰 구입', path: '/coupon', queryParams: {}},
        {icon: 'fmd_bad', name: '서비스 안내', path: '/info/my-house', queryParams: {}}
      ]
    } else if (type === 'OPEN_HOUSE') {
      links = [
        {icon: 'notifications', name: '오픈 부동산 알림신청', path: '/house/open', queryParams: {}},
        {icon: 'edit_notifications', name: '오픈 부동산 등록', path: '/house/register', queryParams: {type: 'OPEN_HOUSE'}},
        {icon: 'format_list_numbered_rtl', name: '일괄 연장', path: '/house/extends', queryParams: {}},
        {icon: 'bookmark_add', name: '쿠폰 구입', path: '/coupon', queryParams: {}},
        {icon: 'fmd_bad', name: '서비스 안내', path: '/info/open-house', queryParams: {}},
      ]
    } else if (type === 'SERVICE') {
      links = [
        {icon: 'favorite', name: '이벤트', path: '/info/events', queryParams: {type: 'EVENT'}},
        {icon: 'error', name: '공지사항', path: '/info/notice', queryParams: {type: 'NOTICE'}},
        {icon: 'help_center', name: '1 : 1 문의', path: '/inquire', queryParams: {type: 'INQUIRE'}},
        {icon: 'pest_control', name: '오류신고', path: '/inquire', queryParams: {type: 'ERROR'}},
        {icon: 'warning', name: '기능요청', path: '/inquire', queryParams: {type: 'SUGGEST'}},
        {icon: 'nature_people', name: '회사소개', path: '/info/company', queryParams: {}},
      ]
    } else {
      links = [
        {icon: 'portrait', name: '마이 페이지', path: '/home', queryParams: {}},
        {icon: 'lock_open', name: '정보변경', path: '/home/info', queryParams: {}},
        {icon: 'bookmark_border', name: '쿠폰함', path: '/home/coupon', queryParams: {}},
        {icon: 'report_gmailerrorred', name: '결제내역', path: '/home/purchase', queryParams: {}},
        {icon: 'link', name: 'SNS 계정관리', path: '/home/sns', queryParams: {}},
        {icon: 'sentiment_very_dissatisfied', name: '로그아웃', logout: true}
      ]
    }

    links.forEach(link => link.type = type)

    this.matBottomSheet.open(MobileFooterSheetComponent, {data: {links}})
      .afterDismissed()
      .subscribe(response => {
        this.log.debug(response);
      })
  }

  private checkCountNotifications() {
    if (this.isLogin) {
      this.notificationService.countNotifications()
        .subscribe(count => {
          this.countNotifications = count > 0 ? count : undefined;
        })
    }
  }

  private checkMobileFooterIcon(event: NavigationEnd) {
    const currentUrl = event.url;

    if (currentUrl === '/house/list' || currentUrl.includes('/house/detail') || currentUrl.includes('/house/map') || currentUrl.includes('/house/statistics') || currentUrl.includes('/info/my-house')) {
      this.activeMobileFooterIcon = 'MY_HOUSE'
    } else if (currentUrl.includes('/house/open') || currentUrl.includes('OPEN_HOUSE') || currentUrl.includes('/info/open-house')) {
      this.activeMobileFooterIcon = 'OPEN_HOUSE'
    } else if (currentUrl.includes('/house/extends')) {
      this.activeMobileFooterIcon = ''
    } else if (currentUrl.includes('/house')) {
      this.activeMobileFooterIcon = 'HOUSE_REGISTER'
    } else if (currentUrl.includes('/home')) {
      this.activeMobileFooterIcon = 'HOME'
    } else if (currentUrl.includes('/analysis')) {
      this.activeMobileFooterIcon = 'ANALYSIS'
    }  else if (currentUrl.includes('/info') || currentUrl.includes('/inquire')) {
      this.activeMobileFooterIcon = 'SERVICE'
    } else {
      this.activeMobileFooterIcon = ''
    }
  }

  protected readonly Analysis = Analysis;
}
