import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {environment} from '../environments/environment';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButton, MatButtonModule} from '@angular/material/button';
import {MainModule} from './pages/main/main.module';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {MAT_CHECKBOX_DEFAULT_OPTIONS} from '@angular/material/checkbox';
import {COMPOSITION_BUFFER_MODE} from '@angular/forms';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {SharedModule} from '../../projects/shared/src/lib/shared.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {DefaultHttpInterceptor} from './_interceptor/default-http.interceptor';
import {LoginDialogModule} from './shared/dialog/login-dialog/login-dialog.module';
import {RefreshComponent} from './refresh/refresh.component';
import {InfoDialogModule} from './shared/dialog/info-dialog/info-dialog.module';
import {MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MobileFooterSheetModule} from './shared/bottom-sheet/mobile-footer-sheet/mobile-footer-sheet.module';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {Paginator} from './_i18n/paginator';
import {DeviceDetectorService} from 'ngx-device-detector';
import {MatBadgeModule} from '@angular/material/badge';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from "@angular/material/tooltip";
import {ScreenTrackingService} from "@angular/fire/compat/analytics";
import {defineElement} from "lord-icon-element";
import lottie from "lottie-web";
import {NgxPullToRefreshModule} from "ngx-pull-to-refresh";
import {ScrollModule} from "./shared/scroll/scroll.module";
import {GoogleTagManagerModule} from "angular-google-tag-manager";
import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {getAnalytics, provideAnalytics} from "@angular/fire/analytics";
import {provideMomentDateAdapter} from "@angular/material-moment-adapter";
import "moment/locale/ko";
import {KO_DATE_FORMATS} from "./_i18n/date_format";
import {MAT_DATE_LOCALE} from "@angular/material/core";

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, RefreshComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    LoggerModule.forRoot({level: environment.production ? NgxLoggerLevel.OFF : NgxLoggerLevel.DEBUG}),
    SharedModule,
    MatToolbarModule,
    MatBadgeModule,
    MatButtonModule,
    MatButton,
    MatMenuModule,
    MatIconModule,
    MainModule,
    LoginDialogModule,
    InfoDialogModule,
    MobileFooterSheetModule,
    NgxSpinnerModule,
    NgxPullToRefreshModule,
    ScrollModule,
    GoogleTagManagerModule.forRoot({id: environment.googleTagManagerId}),
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'ko-KR'},
    provideMomentDateAdapter(KO_DATE_FORMATS, {useUtc: true}),
    {provide: COMPOSITION_BUFFER_MODE, useValue: false},
    {provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: {color: 'primary'}},
    {provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: {color: 'primary'}},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline', hideRequiredMarker: true}},
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {touchGestures: 'auto'}},
    {provide: HTTP_INTERCEPTORS, useClass: DefaultHttpInterceptor, multi: true},
    {provide: 'API_HOST', useValue: environment.apiUrl},
    {provide: MatPaginatorIntl, useClass: Paginator},
    DeviceDetectorService,
    ScreenTrackingService,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics())
  ]
})
export class AppModule {
  constructor() {
    defineElement(lottie.loadAnimation);
  }
}
